import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Renderer2, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'cdg-playbutton',
  templateUrl: './playbutton.component.html',
  styleUrls: ['./playbutton.component.scss']
})
export class PlaybuttonComponent implements OnInit, AfterViewInit {

  @ViewChild('stroke') stroke: ElementRef;
  @ViewChild('play') play: ElementRef;
  @ViewChild('pause') pause: ElementRef;
  @ViewChild('button') button: ElementRef;

  // usage in parent:
  //  <app-playbutton (pausedPlayEvent)="handlePlayButtonEvent($event)"></app-playbutton>
  @Output() pausedPlayingEvent = new EventEmitter();
  @Input() duration: number;
  @Input() backgroundColor: string;
  @Input() buttonWidth: string;

  playing = false;
  constructor(private renderer2: Renderer2) { }

  ngOnInit() {
    // console.log('%c ** stroke **  ', 'color:white;background:red', this.stroke);
    console.log('%c ** input duration **  ', 'color:white;background:red', this.duration);

    // console.log('play', this.play.nativeElement)
    // console.log('pause', this.pause.nativeElement)

    // ok here?
    this.renderer2.setStyle(this.button.nativeElement, 'background-color', `${this.backgroundColor}`)

    if (this.buttonWidth) {
      this.renderer2.removeStyle(this.button.nativeElement, 'width')
      this.renderer2.setStyle(this.button.nativeElement, 'width', `${this.buttonWidth}`)
    }
  }

  ngAfterViewInit() {
    // this.renderer2.setStyle(this.button.nativeElement, 'background-color', `${this.backgroundColor}`)
  }

  gClick() {
    this.playing = !this.playing
    // console.log('%c ** gClick **  ', 'color:white;background:red', this.playing);

    if (this.playing) {
      // dont forget the 's' at the end of this.duration
      const animation = `dash ${this.duration}s linear infinite running`;
      this.renderer2.setStyle(this.stroke.nativeElement, 'animation', animation);


      this.renderer2.addClass(this.play.nativeElement, 'hidden')
      this.renderer2.removeClass(this.pause.nativeElement, 'hidden')

      // console.log('play', this.play.nativeElement)
      // console.log('pause', this.pause.nativeElement)

      // emit playing
      this.pausedPlayingEvent.emit('playing');
    }
    else {
      // dont forget the 's' at the end of this.duration
      const animation = `dash ${this.duration}s linear infinite paused`;
      this.renderer2.setStyle(this.stroke.nativeElement, 'animation', animation);


      this.renderer2.addClass(this.pause.nativeElement, 'hidden')
      this.renderer2.removeClass(this.play.nativeElement, 'hidden')

      // console.log('play', this.play.nativeElement)
      // console.log('pause', this.pause.nativeElement)

      // emit paused
      this.pausedPlayingEvent.emit('paused');
    }
  }

}
