import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popmainmenu',
  templateUrl: './popmainmenu.component.html',
  styleUrls: ['./popmainmenu.component.scss']
})
export class PopmainmenuComponent implements OnInit {

  passedId = null;

  // NavParams must be provided see app.module.ts providers[]
  // errro can't resolve all param for navParams
  // constructor(private navParams: NavParams, private popoverController: PopoverController) { }

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {
    // this.passedId = this.navParams.get('custom_id');
  }

  itemClick(n: number) {
    console.log('itemClick ', n);
    this.closePopover();
  }

  closePopover() {
    this.popoverController.dismiss();
  }

}
