import { CdgDecoderService } from './../services/cdgDecoder.service';
import { CdgHelperService } from './../services/cdgHelper.service';
import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

import { OnChanges, SimpleChanges } from '@angular/core';

import { Playlist } from './../models/playlist.model';
import { Song } from './../models/song.model';

import { Observable, Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';

import { PlaylistService } from '../services/playlist.service';
import { AudioService } from '../services/audio.service';
import { ControlService, ControlStatus } from '../services/control.service';
import { LoaderService } from '../services/loader.service';

 
  /**
   * TO Use as Observable in client
   *
   *  <cdg-karaoke [song$]="song$.asObservable()"></cdg-karaoke>
   * 
   *  in Client:
   *    song$: Subject<any> = new Subject<any>();
   *      or
   *    (BETTER FOR TYPE CHECKING)
   *    song$: Subject<Song[]> = new Subject<Song[]>();
   *
   *    songs.push(newSong)
   *    this.song$.next(songs) where songs is the array of Song
   *
   *  TODO: Do we want to use song$ or playlist$??
   */
@Component({
  selector: 'cdg-karaoke',
  templateUrl: './karaoke.component.html',
  styleUrls: ['./karaoke.component.css']
})
export class KaraokeComponent implements OnInit, AfterViewInit, OnDestroy {
  // Karaoke canvas component
  @ViewChild("cdg_border") border: ElementRef;
  @ViewChild("cdg_canvas") canvas: ElementRef;

  @Input() canvasWidth = '100%'      // defaults to 100%
  @Input() borderWidth= '100%'       // defaults to 100%
  @Input() backgroundColor = '#000'  // black, #f00 red
  @Input() textAlign = 'center'      // left, right, start, unset, etc
                                     // in case we want to overlay controls

  // private _song$ = new BehaviorSubject<Song[]>([]);  // empty songs
  // @Input() song$: Observable<Song[]>;  // empty songs
  // @Input() songs: Song[];
    @Input() song: Song;

  // Subscription
  playlist$subscription: Subscription = null;

  constructor( 
    private playlistSrvc: PlaylistService,
    private cdgHelper: CdgHelperService,
    private cdg: CdgDecoderService,
    // private audio: AudioService,
    private ctrl: ControlService,

    private loader: LoaderService   // need this to init constructor for lifetime of app
    ) { 

    // console.log('%c ** LIB V3 karaokeComponent() **  ', 'color:black;background:red', this.song$); 
  }

  ngOnInit() {
    // console.log('%c ** LIB V2 karaokeComponent ngOnInit() **  ', 'color:white;background:green', this.song$);
  }

  ngOnDestroy() {
    // console.log('%c ** cdg-karaoke OnDestroy **  ', 'color:white;background:red');
    // console.log('** cdg-karaoke OnDestroy **  ');
  }

  ngAfterViewInit() {
    this.cdg.init(this.canvas.nativeElement, this.border.nativeElement);
    this.ctrl.cdgUpdateOnPause(); // redisplay cdg canvas at audio currentTime(when paused)

    // Now using LoaderService to load audio and cdg
  }

  play() {
    this.ctrl.play()
  }

  pause() {
    this.ctrl.pause()
  }

  stop() {
    this.ctrl.stop()
  }

  replay() {
    this.ctrl.replay()
  }

  // <cdg-karaoke [song]="song"></cdg-karaoke>
  ngOnChanges(changes: SimpleChanges ) {
    console.log('%c ** cdg-karaoke-lib::karaoke:ngOnChanges() **  ', 'color:white;background:#41b883');
  
    if (changes['song']) {
      //if (!this.isEmpty(this.song)) {
      if (this.keyExist("cdgUrl", this.song)) {
        console.log('** %c cdg-karaoke-lib changes[songs] **  ', 'color:white;background:red', this.song);

        /**
         *  DESIGN DECISION
         *  1. Do we want to stop whats already playing?
         *  2. Do we want to ignore unless client stop playing (or paused)?
         * 
         * action: just stop what is playing
         * 
         * AUDIO POLICY
         *  1. startPlaying = true may not work in the future
         *   (ios and android requires touch feedback before sound can be played)
         * 
         * TODO: on page changes, ngOnChanges will reset so the same song can trigger
         *       replay.
         * 
         *  RESOLUTION: Let Client worry about it, they need to keep track, otherwise,
         *    were putting too much smarts logic to this library. Should be simple and
         *    dumb (minimize side effects)
         * 
         */
        this.ctrl.stop();
        // this.loader.loadCdgAndAudio(this.song, /* startPlaying */ true)
        this.loader.loadCdgAndAudio(this.song); // defaults to startPlaying false
      }
      else {
        console.error('key cdgUrl does not exist or is empty');
      }
    }
  }

  //https://coderwall.com/p/_g3x9q/how-to-check-if-javascript-object-is-empty
  private isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  //https://stackoverflow.com/questions/1098040/checking-if-a-key-exists-in-a-javascript-object
  private keyExist(key, obj) {
    if (key in obj) return true
    return false;
  }

  /**
   * DETECTS ALL CHANGES TO INPUT
   *
   * <cdg-karaoke [song$]="song$.asObservable()" [songs]="songs"></cdg-karaoke>
   *   input [song$] - the obserable
   *   input [songs] - just an array (! see note below about arrays)
   *
   *  **** !!!! ?? NEED THIS TO TRIGGER ONCHANGES ?? !!!! *****
   *  this might be required in client
   *   ---> this.changeDetectorRef.detectChanges();
   *
   *  TODO: Enforce client to use one or the other and not both??
   *  IMPORTANT: CHANGE DETECTION WILL NOT OCCUR when pushing into an array
   *     https://stackoverflow.com/questions/34796901/
   *       angular2-change-detection-ngonchanges-not-firing-for-nested-object
   *   see strategies to fix them:
   *   1. Implement ngDoCheck()  # appears to be costly
   *   2. assign new array
   *
   * USE OBVSERVABLES INSTEAD to make life easier and enforce client to use it.
   *
   *  ngOnChanges(changes: SimpleChanges ) {
   *    console.log('%c ** cdg-karaoke-lib::ngOnChanges() V2 **  ', 'color:white;background:green');
   *
   *    if (changes['songs']) {
   *      console.log('** cdg-karaoke-lib changes[songs] **  ', this.songs);
   *    }
   *
   *    if (changes['song$']) {
   *      console.log('** cdg-karaoke-lib changes[song$] **  ', this.songs);
   *    }
   *  }
   */

}
