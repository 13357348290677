import { Component, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';

import { KaraokeComponent } from './../karaoke/karaoke.component';
import { ControlService } from './../services/control.service';

/**
 *  Requires Input to cdg component
 *    <cdg-karaoke #cdg></cdg-karaoke>
 *    <cdg-controls [cdg]="cdg"></cdg-controls>
 */
@Component({
  selector: 'cdg-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit, OnDestroy, AfterViewInit {

  // now using control service
  // @Input() cdg: KaraokeComponent

  constructor(public ctrl: ControlService) { 
  }

  ngOnInit() {
  }

  // todo: check if @Input cdg exists
  //  -- use ctrl service instead
  ngAfterViewInit() {
    // console.log('%c (ControlsComponent) V1 ngAfterViewInit cdg->', 'color:white;background:orange', this.cdg);
  }

  ngOnDestroy() {

  }

  play() {
    // this.cdg.play()
    this.ctrl.play()
  }

  pause() {
    // this.cdg.pause()
    this.ctrl.pause()
  }

  stop() {
    // this.cdg.stop()
    this.ctrl.stop()
  }

  replay() {
    // this.cdg.replay()
    this.ctrl.replay()
  }

}
