import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelloService {

  constructor() { }

  sayHello(arg: string) {
    return `Hello V1 ${arg}`
  }
}
