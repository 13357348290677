import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdgKaraokeLibComponent } from './cdg-karaoke-lib.component';

import { KaraokeComponent } from './karaoke/karaoke.component';
import { PlaylistComponent } from './playlist/playlist.component';

import { HelloService } from './services/hello.service';
import { PlaylistService } from './services/playlist.service';
import { AudioService } from './services/audio.service';
import { ControlService } from './services/control.service';
import { LoaderService } from './services/loader.service';


// Angular Material Virtual Scroll and Drag and Drop
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ControlsComponent } from './controls/controls.component';
import { PlaybuttonComponent } from './playbutton/playbutton.component';
import { Karaokev2Component } from './karaokev2/karaokev2.component';

// loaded in index.html
// // Font-Awesome
// import { AngularFontAwesomeModule } from 'angular-font-awesome'

@NgModule({
  declarations: [
    CdgKaraokeLibComponent,
    KaraokeComponent,
    PlaylistComponent,
    ControlsComponent,
    PlaybuttonComponent,
    Karaokev2Component
  ],
  imports: [
    // ngFor (also ngIf?)
    CommonModule,

    // Angular Material
    ScrollingModule,
    DragDropModule,

    // // Font Awesome
    // AngularFontAwesomeModule

  ],
  exports: [
    CdgKaraokeLibComponent,

    // also, add to public.api, and rebuild library
    KaraokeComponent,
    PlaylistComponent,
    ControlsComponent,
    PlaybuttonComponent,
    Karaokev2Component
  ]
})
export class CdgKaraokeLibModule {
  static forRoot() {
    return {
      ngModule: CdgKaraokeLibModule,

      // Singleton Services, also add to public.api, and rebuild library
      // Do not inject services into another services in constructor with
      // each other, else get Circular dependecy error (cyclic dependency in chrome console)
      providers: [
        HelloService,
        PlaylistService,
        AudioService,
        ControlService,
        LoaderService
      ]
    }
  }
}
