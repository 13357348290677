import { Injectable } from '@angular/core';

import { Playlist } from '../models/playlist.model'
import { Song } from './../models/song.model';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  // For client to subscribe -> playlist$.asObservable()
  playlist: Playlist = { id: null, songs: [] }; // default to 1 and empty
  //playlist$: Subject<Playlist> = new Subject<Playlist>();

  // // All will get null
  // playlist$: BehaviorSubject<Playlist> = new BehaviorSubject<Playlist>(null);

  // use empty playlist instead
  playlist$: BehaviorSubject<Playlist> = new BehaviorSubject<Playlist>(this.playlist);

  constructor() { }

  init(id: number, songs: Song[]) {
    this.playlist.id = id;
    this.playlist.songs = songs;
    this.broadcast();
  }

  getPlaylist$() {
    return this.playlist$.asObservable();
  }

  getPlaylist(){
    return this.playlist;
  }

  addSong(song: Song) {
    this.playlist.songs.push(song)
    this.broadcast();
  }

  broadcast() {
    this.playlist$.next(this.playlist)
  }
}
