import { Injectable, NgZone } from '@angular/core';
import { Subject, Observer, Observable, Subscription } from 'rxjs';

import { CdgDecoderService } from './cdgDecoder.service';
import { CdgHelperService } from './cdgHelper.service';

import { ControlService, ControlStatus } from '../services/control.service';
import { AudioService } from './audio.service';
import { PlaylistService } from './playlist.service';
import { Playlist } from './../models/playlist.model';
import { Song } from './../models/song.model';

export enum LoaderStatus {
    NONE,                 // 0 -> Default
    CDGLOADING,
    CDGCOMPLETE,
    AUDIOLOADING,
    AUDIODECODING,
    AUDIOCOMPLETE,
    CDGERROR,
    AUDIOERROR,
}

export interface KarDataBuffer {
  id: number      // need this so we can store in local storage ??
  cdgData: string
  audioData: AudioBuffer
}


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  // TODO: Use local storage to save this
  MAX_BUFFER = 5;  // default
  buffer: KarDataBuffer[] = [];  // init to empty list
  /**
   *  todo: ideas
   *  1. Add to playlist service list (already hav songs array)
   *  2. buffer will hold downloaded songs data
   *  3. NEED TO DRAW DIAGRAM FOR THIS LOGIC !!!
   * 
   */



  // RXJS
  private loaderStatus$: Subject<LoaderStatus> = new Subject<LoaderStatus>();

  // SUBSCRIPTION
  playlist$subscription: Subscription = null;

  constructor(private _zone: NgZone, private playlistSrvc: PlaylistService, private ctrl: ControlService,
    private audioSrvc: AudioService, private cdgHelper: CdgHelperService, private cdg: CdgDecoderService) { 
    console.log('** cdg-karaoke-lib:LOADER CONSTRUCTOR **  ');

    this.playlist$subscription = this.playlistSrvc.getPlaylist$().subscribe(
      (playlist: Playlist) => {
        // console.log('%c ** cdg-karaoke-lib:karaokeComponent playlist$.subscribe **  ', 'color:white;background:#41b883', playlist);
        console.log('** cdg-karaoke-lib:LOADER SERVICE $.subscribe **  ', playlist);

        // Since this is a behavioralSubject, init playlist is empty, so lets get out if so
        // console.log('%c ** playlist **  ', 'color:white;background:red', playlist);

        // TODO: check if we are already playing so we dont play
        // TODO: a better way to do this, perhaps do not use BehevioralSubject
        console.log('%c cdg-karaoke-lib::CTRL.status ', 'color:white;background:#41b883', ControlStatus[this.ctrl.getStatus()]);

        /********************************/
        // refactor to be more elegant //
        switch (this.ctrl.getStatus()) {
          case ControlStatus.PAUSED:
          case ControlStatus.PLAYING:
            return;
            break;
        }

        // could also check if id is null since we init that, but better checking if songs[] is empty
        if (playlist.songs.length > 0 ) {
          console.log('*** loader.service.ts::commented -> testing cdg and mp3 http get and decode ..... ')

          //this.cdgHelper.TestOnlyCdgPlaySong(playlist.songs[0], this.canvas, this.border);
          //this.loadCdgAndAudio(playlist.songs[0]);
        }
        else {
          //console.log('%c ** songs[] is empty **  ', 'color:white;background:red');
          console.log('** songs[] is empty **  ');
        }

      }
    )
  } // end constructor

  getLoaderStatus$() {
    return this.loaderStatus$.asObservable();
  }

  loadCdgAndAudioBuff$(song: Song): Observable<any> {

    return Observable.create(

      (observer: Observer<any>) => {

        this.loadCdg$(song).subscribe(
          cdgData => { 
              this.loadAudioBuff$(song).subscribe(
                arrBuff => {
                  this._zone.run(() => observer.next(arrBuff))
                }, 
                error => { 
                  this._zone.run(() => observer.error(error));
                }
              )
          },
          error => { 
            this._zone.run(() => observer.error(error));
          }
        );
      }
    ) // end return

  }

  loadCdg$(song: Song): Observable<any> {
    return Observable.create(
      (observer: Observer<any>) => {

        this.loaderStatus$.next(LoaderStatus.CDGLOADING);
        this.cdgHelper.HttpGetCdg(song.cdgUrl.cdgUrl)
        .subscribe( 
          // success
          (cdgData) => {

            // *******************************************
            this.cdg.setCdgData(cdgData);
            this.loaderStatus$.next(LoaderStatus.CDGCOMPLETE);
            this._zone.run(() => observer.next(cdgData))

            console.log('Success: readAsBinaryString')
            console.log('-- cdgData length', cdgData.length);
          }, //error
          (error) => {
            this.loaderStatus$.next(LoaderStatus.CDGERROR);
            this._zone.run(() => observer.error(error));
          }
        )
      }
    ); // end return
  }

  loadAudioBuff$(song: Song): Observable<any> {
    return Observable.create(
      (observer: Observer<any>) => {

        console.log('getting audio .... ');
        this.loaderStatus$.next(LoaderStatus.AUDIOLOADING);
        this.cdgHelper.HttpGetAudio(song.cdgUrl.mp3Url)
        .subscribe(
            // success
            arrayBuffer => {
                this._zone.run(() => observer.next(arrayBuffer))
            }, //error
            error => {
                this.loaderStatus$.next(LoaderStatus.AUDIOERROR);
                this._zone.run(() => observer.error(error));
          }
        )
      }
    ); // end return
  }

  decodeAudioBuff$(arrayBuffer: ArrayBuffer): Observable<any> {
    return Observable.create(
      (observer: Observer<any>) => {

        console.log('getting audio .... ');
        this.loaderStatus$.next(LoaderStatus.AUDIODECODING);

          this.cdgHelper.DecodeAudio$(arrayBuffer)
          .subscribe(audio => {
                this.loaderStatus$.next(LoaderStatus.AUDIOCOMPLETE);

                // do this somewhere else? *********!!!!!!*****
                this.audioSrvc.setAudioBufferWithCallback(audio, 
                  () => this.cdg.setAudioDuration(this.audioSrvc.getDuration())
                );

                // try to unlock ios again here?????? *********
                // todo (ios testing)
                // this.audioSrvc.unlockWebAudio();

                this._zone.run(() => observer.next(audio))
            }, //error
            error => {
                this.loaderStatus$.next(LoaderStatus.AUDIOERROR);
                this._zone.run(() => observer.error(error));
          }
        )
      }
    ); // end return
  }


  // Note: this.ctrl.play assumes canvas has been init from component
  // TODO: Separate this into two Observables, cdg and audio load
  // or could also use loaderStatus$
  loadCdgAndAudio (song: Song, startPlaying: Boolean = false) {

    //todo: check MAX_BUFFER
    // make an observable? return new Observable ...

    this.loaderStatus$.next(LoaderStatus.CDGLOADING);
    this.cdgHelper.HttpGetCdg(song.cdgUrl.cdgUrl)
    .subscribe( 
      // success
      (cdgData) => {

                    // TODO 
                    // save this in buffer, download next

                    // do this somewhere else?

        // *******************************************
        this.cdg.setCdgData(cdgData);
        this.loaderStatus$.next(LoaderStatus.CDGCOMPLETE);

        console.log('Success: readAsBinaryString')
        console.log('-- cdgData length', cdgData.length);

        // *******************************************
        console.log('getting audio .... ');
        this.loaderStatus$.next(LoaderStatus.AUDIOLOADING);
        this.cdgHelper.HttpGetAudio(song.cdgUrl.mp3Url)
        // success
        .subscribe(
          arrayBuffer => {

              this.loaderStatus$.next(LoaderStatus.AUDIODECODING);

              this.cdgHelper.DecodeAudio$(arrayBuffer)
              .subscribe(audio => {

                  this.loaderStatus$.next(LoaderStatus.AUDIOCOMPLETE);
                  console.log('%c ** audio decoded **  ', 'color:white;background:green');

                  // TODO 
                  // save this in buffer, download next
                  // ****************************************
                  const karDataBuffer: KarDataBuffer = {
                    id: 1,
                    cdgData: cdgData,
                    audioData: audio
                  }
                  // todo: do i need to check max size here or will it give error?(verify)
                  this.buffer.push(karDataBuffer);
                  console.error('buffer------->', this.buffer);

                  // do this somewhere else?
                  this.audioSrvc.setAudioBufferWithCallback(audio, 
                    () => this.cdg.setAudioDuration(this.audioSrvc.getDuration())
                  );

                // see new
                  // // try to unlock ios again here?????? *********
                  // // todo  (ios testing)
                  // this.audioSrvc.unlockWebAudio();

                  // // ********** play **************
                  // // this.audioSrvc.play();
                  // // this.cdg.playCdg(() => this.audioSrvc.getCurrentTime());

                  // // combined, play() and playCdg(), will also set status
                  // // TODO: AUDIO POLICY
                  // //  -- dont start playing??

                  // if (startPlaying)
                  //   this.ctrl.play();


                  // new
                this.audioSrvc._webAudioTouchUnlock().then(
                  // resolved
                  (unlocked) => {
                    if (unlocked) {
                        // AudioContext was unlocked from an explicit user action,
                        // sound should start playing now
                        console.error('unlocked')
                    } else {
                        // There was no need for unlocking
                        console.error('no need to unlock audio')
                    }


                    console.log('%c ** loader startPlaying **  ', 'color:white;background:red', startPlaying);

                    if (startPlaying) {
                      console.log('**** loader.service calling this.ctrl.play()')
                      this.ctrl.play();
                    }

                  },
                  // rejected
                  (reason) => {
                    console.error(reason);
                  }
                ); // end audioServc(audio=>)

              } // end audio => { ..
            ); // end subscribe(audio=>)

          } // end arrayBuffer =>
        ); // end subscribe(arrayBuffer=>)

      } // end (cdgData)
    ) // end subscribe(cdgData)

  } // end loadCdgAndAudio()

}
