import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpanlettersDirective } from './spanletters.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [SpanlettersDirective],

  // to use components outside of this module
  exports: [
    SpanlettersDirective
  ]
})
export class AnimeJsDirectiveModule {}
