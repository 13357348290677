import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CdgDecoderService, CdgEvent } from './cdgDecoder.service';
import { AudioService } from './audio.service';

export enum ControlStatus{
    NONE,          // 0 -> Default
    PLAYING,    
    PAUSED,
    STOPPED
}

@Injectable({
  providedIn: 'root'
})
export class ControlService implements OnDestroy {
  iosHack = true;

  private status: ControlStatus = ControlStatus.NONE;
  private cdgEvent$subscription: Subscription = null;
  private loaderStatus$subscription: Subscription = null;

  // Another Service can not be injected in constructor
  constructor(private audio: AudioService, private cdg: CdgDecoderService) {

    // console.error('ControlService constructor')

    // SUBSCRIBE to CDG EVENTS
    this.cdgEvent$subscription =  this.cdg.getEvent$()
      .subscribe(event => {

        // CdgEvent[event] => returns the Enum string, i.e. 'LOADED'
        switch (event) {
          case CdgEvent.LOADED:
            console.log('%c ** ControlService cdg LOADED **', 'color:blue');
            break;

          case CdgEvent.END:
            // CdgDecoderService will know when audio duration has elapsed, so
            // it will know when audio stopped and it will then trigger CdgEvent.END.
            this.status = ControlStatus.STOPPED;

            console.log('%c ** ControlService cdg END **', 'color:blue');
            console.log('%c ** ControlService ControlStatus.STOPPED **', 'color:blue');
            break;
        }
    })

  }

  ngOnDestroy() {
    this.cdgEvent$subscription.unsubscribe();
  }

  getIosHack(): boolean {
    return this.iosHack
  }

  // added for conveniance testing
  // probably should be removed
  setIosHack(state: boolean) {
    this.iosHack = state;
  }

  // todo: needs to refactor
  // perhaps refactor audioService webUnlockAudio
  // or in audio.play(). Also try loading dummy sound.
  play() {

    if (this.iosHack) {
      this.iosPlayStart();
      this.iosHack = false;
    } else {
      this.playN()
    }
  }

  iosPlayStart() {

    this.playN()
    this.pause();

    console.error('pausing for 1000ms')
    setTimeout( () => {
      this.playN()
      }, 1000
    );
  }

  playN() {
    this.status = ControlStatus.PLAYING;

    // console.error(ControlStatus.PLAYING);                 // 1
    // console.error(ControlStatus[ControlStatus.PLAYING]);  // PLAYING
    // console.error(this.status);                           //  1

    if (this.audio.play()) {
      console.log('audio is already playing...returning');
      return;
    }

    console.log('calling cdg.playCdg(() => callback');
    this.cdg.playCdg(() => this.audio.getCurrentTime());

  }

  pause() {
    this.status = ControlStatus.PAUSED;

    this.cdg.pauseCdg();
    this.audio.pause();
  }

  stop() {
    this.status = ControlStatus.STOPPED;

    this.audio.stop();
    this.cdg.pauseCdg();  // resets timer also
  }

  replay() {
    this.stop()
    this.audio.reinit()
    this.play()
  }
  
  seekAhead(seconds: number) {
    this.audio.seekAhead(seconds);
  }

  seekBack(seconds: number) {
    this.audio.seekBack(seconds);
  }

  getStatus(): ControlStatus {
    return this.status;
  }

  cdgUpdateOnPause() {
    if (this.status === ControlStatus.PAUSED)
      this.cdg.update_play_position_func(() =>  this.audio.getCurrentTime() );
  }

  getAudioDuration(): number {
    return this.audio.getDuration();
  }

  getAudioCurrentTime(): number {
    return this.audio.getCurrentTime();
  }

  getAudioCurrentTimeHStr(): string {
    return this.audio.getCurrentTimeHStr();
  }
}
