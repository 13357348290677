import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cdg-cdg-karaoke-lib',
  template: `
    <p>
      cdg-karaoke-lib works!
    </p>
  `,
  styles: []
})
export class CdgKaraokeLibComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
