import { ControlService } from './../services/control.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PlaylistService } from '../services/playlist.service';
import { Playlist } from '../models/playlist.model';

import { Song } from './../models/song.model';
import { Artist } from './../models/artist.model';
import { CdgUrl } from './../models/cdgUrl.model';

import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { LoaderService, LoaderStatus } from '../services/loader.service';

@Component({
  selector: 'cdg-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit, OnDestroy {

  loading = false;
  startPlaying = false;
  currentSong: Song = { id: 0, title: '', artist: null , cdgUrl: null, albumArt: null }

  // Subscription
  playlist$subscription: Subscription = null;

  testMember = 1;
  playlist: Playlist = { id: null, songs:[] }; // init

  loaderStatus$subscription: Subscription = null;

//   playlist = [
//     {
//         "id": 1, 
//         "title": "Here Without You [CB Karaoke]",
//         "artist": { "id": 1, "name":"3 Doors Down" },
//         "cdgUrl": 
//             {
//                 "cdgUrl": "assets/sample/3 Doors Down - Here Without You [CB Karaoke].cdg",
//                 "mp3Url": "assets/sample/3 Doors Down - Here Without You [CB Karaoke].mp3"
//             }
//     },
//     {
//         "id": 2, 
//         "title": "When We Were Young [Z Karaoke]",
//         "artist": { "id": 2, "name":"Adele" },
//         "cdgUrl":
//             {
//                 "cdgUrl": "assets/sample/Adele - When We Were Young [Z Karaoke].cdg",
//                 "mp3Url": "assets/sample/Adele - When We Were Young [Z Karaoke].mp3"
//             }
//     },
//   ]

  constructor(
    private playlistSrvc: PlaylistService, private http: HttpClient, 
    private ctrl: ControlService,
    private loader: LoaderService ) { 

      console.log('%c ** cdg-karaoke-lib playlist.component() **  ', 'color:white;background:red');

  }

  // ngOnInit will init every route changes (verify)
  ngOnInit() {
      // console.log('%c playlistcomponent ngOnInit()  ', 'color:white;background:#41b883');

    this.playlist$subscription = this.playlistSrvc.getPlaylist$().subscribe(
      (playlist: Playlist) => {

        this.playlist = playlist;
        console.log('  -- playlist.component playlist ', this.playlist);

      }
    )

    this.loaderStatus$subscription = this.loader.getLoaderStatus$()
    .subscribe(status => {
      console.log('%c ** LoaderStatus **  ', 'color:white;background:orange', LoaderStatus[status]);

      // export enum LoaderStatus {
      //   NONE,                 // 0 -> Default
      //   CDGLOADING,
      //   CDGCOMPLETE,
      //   AUDIOLOADING,
      //   AUDIODECODING,
      //   AUDIOCOMPLETE,
      //   CDGERROR,
      //   AUDIOERROR,
      switch (status) {
        case LoaderStatus.CDGLOADING:
          break;

        case LoaderStatus.CDGCOMPLETE:
          break;

        case LoaderStatus.AUDIOLOADING:
          this.loading = true;
          break;

        case LoaderStatus.AUDIODECODING:
          break;

        case LoaderStatus.AUDIOCOMPLETE:
          this.loading = false;
          this.currentSong = { id: 0, title: '', artist: null , cdgUrl: null, albumArt: null }
          break;
      }
  })

  }

  ngOnDestroy() {
    this.playlist$subscription.unsubscribe();
    this.loaderStatus$subscription.unsubscribe();
  }

  drop($event: CdkDragDrop<{title: string, poster: string}[]>) {
    moveItemInArray(this.playlist.songs, $event.previousIndex, $event.currentIndex);

    console.log('-- playlist.component', this.playlist)
  }

  // TESTING ONLY
  addSong() {

    let song = {} as Song;
    this.playlistSrvc.addSong(song);

  }

  getSongs() {
    this.getSongsJson().subscribe(
      (rsp: Song[]) => {

        // TESTING
        console.log('%c rsp  ', 'color:white;background:#41b883', rsp);
        const songs: Song[] = rsp;
        console.log('%c ** songs **  ', 'color:white;background:green', songs);
        console.log('%c ** song[1] **  ', 'color:white;background:green', songs[1]);
        const artist: Artist = songs[1].artist;
        console.log('%c ** artist **  ', 'color:white;background:green', artist);
        const cdgUrl: CdgUrl = songs[1].cdgUrl;
        console.log('%c ** cdgUrl **  ', 'color:white;background:green', cdgUrl);

        console.log('mp3Url -> ', cdgUrl.mp3Url);
        console.log('cdgUrl -> ', cdgUrl.cdgUrl);


        // NOTIFY CDG KARAOKE CLIENT
        // this.song$.next(rsp);

        this.playlistSrvc.init(1, songs);
      }
    )
  }

  getSongsJson() {
    const url = 'assets/sample/mock-playlist.json'
    return this.http.get(url)
          //  .pipe(map (rsp => rsp.json()))
           ;
  }

  setStartPlaying(status: boolean) {
    this.startPlaying = status;
  }

  playClick(song: Song, startPlaying: boolean) {
    this.currentSong = song;

    console.log('%c ** playClick **  ', 'color:white;background:red', song);

    this.ctrl.stop();

    if (startPlaying) {
      this.loader.loadCdgAndAudio(song, /* startPlaying */ true)
    }
    else {
      this.loader.loadCdgAndAudio(song); // defaults to startPlaying false
    }

    // // test new func
    // this.loader.loadCdgAndAudioBuff$(song).subscribe(arrBuff => {
    //     // todo show toast or use LoaderStatus above
    //
    //     this.loader.decodeAudioBuff$(arrBuff).subscribe(audio => {
    //       // todo end spinnera(loading) or use LoaderStatus above
    //
    //       this.ctrl.play();
    //
    //     })
    //
    // })

  }
}
