import { Injectable, Inject } from '@angular/core';
import * as moment from 'moment';  // $ npm install moment
import { DOCUMENT } from '@angular/platform-browser';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  // private audioCtx = window.AudioContext || window.webkitAudioContext;
  // create by using new, i.e. let ctx = new audioCtx(), dnt forget '()'
  // see also https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio
  // fallback https://github.com/GoogleChromeLabs/airhorn/blob/master/app/scripts/main.min.js

  private sourceNode = null;
  private buffer: AudioBuffer = null;

  // NOTE! this is only for _audioCtx!
  private startedAt: number = 0;
  private pausedAt: number = 0;
  private playing: boolean = false;
  private duration: number = 0;
  private seekForward = 0;;
  private seekBackward = 0;
  private intervalId;        // to get currentTime for UI

  // debug for setTimeout
  private timerIntervalId = null;
  private debugTimer: number = 0;

  // Audio() obj for stream
  private audioObj = null;  

  // constructor(@Inject(DOCUMENT) private document: any) { 
  constructor() { 
  //  console.log('%c ** DOCUMENT.body **  ', 'color:white;background:red', document.body); 

   // todo: do this only for ios??
   // this._unlockWebAudio();
  }

  reinit() {
    this.startedAt = 0;
    this.pausedAt = 0;
    this.seekForward = 0;
    this.seekBackward = 0;
    this.playing = false;
  }

  // for unlockin ios
  // this.audioSrvc.getAudioContext()
  // unlockWebAudio(this.audioCtx);
  getAudioContext() {
    return this.audioCtx;
  }

  setAudioBuffer(audioData: AudioBuffer) {
    this.buffer = audioData;
    this.duration = audioData.duration;
  }

  /**
   * @param audioData 
   * @param callback 
   *  must be in this form
   *   () => this.cdg.setAudioDuration(this.audioSrvc.getDuration())
   */
  setAudioBufferWithCallback(audioData: AudioBuffer, callback: any) {
    if (this.isFunction(callback)) {
      this.setAudioBuffer(audioData)
      callback()
    }
    else {
      console.error('** callback not a function **')
    }
  }

  // todo: put in Utils service ??
  private isFunction(f): f is Function {
    return f instanceof Function;
  }

  getAudioBuffer() {
    return this.buffer;
  }

  play(): number {
    console.log('** play **')

    if (this.playing || this.buffer === null) { 
      if (this.buffer === null) {
        console.error('audio buffer is null');
      }
      return 1 
    }

    // note: experiment with sound policy
    // const context = new this.audioCtx(); 
    // ** not able to pause or seek ahead

    const offset = this.pausedAt;
    this.sourceNode = this.audioCtx.createBufferSource();
    this.sourceNode.connect(this.audioCtx.destination);
    this.sourceNode.buffer = this.buffer;

    this.sourceNode.start(0, offset);
    this.startedAt = this.audioCtx.currentTime - offset;
    this.pausedAt = 0;
    this.playing = true;

    // console.log('%c ** info ** sourceNode  ', 'color:white;background:red', this.sourceNode);
    console.log('%c ** info ** offset      ', 'color:white;background:red', offset);

    return 0;
  }

  pause(): void {
    console.log('** pause **')

    // note: experiment with sound policy
    // const context = new this.audioCtx(); 
    // ** not be able to pause or seek ahead

    const elapsed = this.audioCtx.currentTime - this.startedAt;

    this.stop();
    this.pausedAt = elapsed;

  }

  // NOTE! this is only for _audioCtx!
  stop(): void {
    console.log('** stop **')

    // pause it
    if (this.sourceNode) {
      console.log("--> pausing <---");
      this.sourceNode.disconnect();
      this.sourceNode.stop(0);
      this.sourceNode = null;
      this.playing = false;
    } 
    // reset, stop completely
    else { 
      this.reinit();
    }
  }
  
  ////// SEEK FUNCTIONS //////////////////////////////
  // NOTE! this is only for _audioCtx!
  private seek(seconds: number) {
    console.log('** seek **')
    this.pause();

    if (seconds < 0) seconds = 0;
    if (seconds > this.duration) seconds = this.duration;

    // update pausedAt
    this.pausedAt = seconds;
    this.play();

  }

  seekAhead(seconds) {
    this.seekForward = this.getCurrentTime() + seconds;
    console.log('++ahead', this.seekForward);

    if (this.seekForward > this.duration) {
      console.log('no seek, returning')
      return;
    }
    this.seek(this.seekForward);
  }

  seekBack(seconds) {
    this.seekBackward = this.getCurrentTime() - seconds;
    console.log('--back', this.seekBackward);

    if (this.seekBackward < 0) {
      this.seekBackward = 0;
      console.log('no back seek, returning')
      return;
    }
    this.seek(this.seekBackward);
  }

  // currenTime is an ever-increasing hardware timestamp 
  // https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/currentTime
  getCurrentTime(): number {

    // note: experiment with sound policy
    // ** not able to pause or seek ahead
    // const context = new this.audioCtx(); 

    if (this.pausedAt)  { return this.pausedAt; }
    if (this.startedAt) { return this.audioCtx.currentTime - this.startedAt; }

    return 0;
  }

  getDuration(): number {
    return this.duration;
  }

  getCurrentTimeHStr(): string {
    return this.formatTime(this.getCurrentTime()*1000, 'mm:ss')
  }

  /**
   * formatTime(audio.duration * 1000, 'HH:mm:ss')
   * formatTime(getCurrentTime() * 1000, 'mm:ss')
   */ 
  formatTime(time, format) {
    return moment.utc(time).format(format);
  }

  // ios Testing (todo - verify)
  // https://hackernoon.com/unlocking-web-audio-the-smarter-way-8858218c0e09
  // see also for future android chrome:
  // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio
  // private _webAudioTouchUnlock (context)
  public _webAudioTouchUnlock ()
  {

    const context = this.audioCtx;
    return new Promise(function (resolve, reject)
    {
      // testing for ios
      console.log('%c ** context **  ', 'color:white;background:green', context);
      console.log('%c ** context.state **  ', 'color:white;background:red', context.state);

        if (context.state === 'suspended' && 'ontouchstart' in window)
        {
          console.log('%c context.state is suspended and ontouchtstart  ', 'color:white;background:#41b883');
          console.log('--- waiting for touch events before resolving or rejecting')

            var unlock = function()
            {
                context.resume().then(function()
                {
                  console.log('--- removing touch events');
                    document.body.removeEventListener('touchstart', unlock);
                    document.body.removeEventListener('touchend', unlock);

                    resolve(true);
                }, 
                function (reason)
                {
                    reject(reason);
                });
            };

            console.log('--- adding touchevents listener')

            document.body.addEventListener('touchstart', unlock, false);
            document.body.addEventListener('touchend', unlock, false);

            // waiting for touch event
            // NOTE: that it does not resolve or reject yet!
        }
        else
        {
            resolve(false);
        }
    });
  }

  private _unlockWebAudio() {

    this._webAudioTouchUnlock().then(function (unlocked)
    {
      if(unlocked)
      {
          // AudioContext was unlocked from an explicit user action,
          // sound should start playing now
          console.error('unlocked')
      }
      else
      {
          // There was no need for unlocking, devices other than iOS
          console.error('no need to unlock audio other than ios device')
      }
    },
    function(reason)
    {
      console.error(reason);
    });

  }

  unlockWebAudio() {
    this._unlockWebAudio();
  }


} // end AudioService