
/* Info about HTTP request
 *
 *    const options = {
 *      headers: new HttpHeaders({
 *        'Content-Type':  'application/json',  // DEFAULT FOR ANGULAR HTTP!!
 *        'Authorization': 'my-auth-token'
 *      })
 *    };
 *        OR
 *    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
 *    let options = { headers: headers };
 *    usage: http.get(url, options).subscribe(...)
 * 
 *    Pre Ng6 way (must install rxjs-compat)
 *      import { Observable } from 'rxjs/Observable';
 *      import { Observer }   from 'rxjs/Observer';
 * 
 *    TUiLoader type is simply a callback function
 *      DecodeAudio$(buffer: ArrayBuffer, uiloader:TUiLoader): Observable<any>
 *    This also works:
 *      DecodeAudio$(buffer: ArrayBuffer, uiloader:()=>void): Observable<any>
 *    or 
 *      DecodeAudio$(buffer: ArrayBuffer, uiloader): Observable<any>
 *      DecodeAudio$(buffer: ArrayBuffer, uiloader:any): Observable<any>
 * 
 */

declare var window: any; // to get ios audio

import { Injectable, ElementRef } from '@angular/core';
import { NgZone } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, Observer, Subject } from 'rxjs';

import { Song } from './../models/song.model';
import { CdgDecoderService } from './cdgDecoder.service';
import { AudioService } from './audio.service';
import { ControlService } from './control.service';
// import { File } from '@ionic-native/file/ngx';

// Function ptr type that returns void
interface TUiLoader { (): void }

// Function ptr type that returns string (todo: verify)
interface TUiLoaderArgs { (arg: string): void }  

// Makes ure to register provider in app.module.ts providers []
@Injectable({
  providedIn: 'root'
})
export class CdgHelperService {

    // constructor(private _zone: NgZone, private http: HttpClient, private fileApi: File) {}
    constructor(private _zone: NgZone, private http: HttpClient, 
                private cdg: CdgDecoderService, private audioSrvc: AudioService, private ctrl: ControlService ) {}

    // Blob to text
    // note: const options = { responseType: 'blob' as 'blob' };
    FileReaderAsText$(blob: Blob) {
        return Observable.create(
        (observer: Observer<any>) => {

            const reader = new FileReader;
            reader.onloadend = (e) => {

                console.log('FileReaderService$ onloadend');
                this._zone.run(() => observer.next(reader.result));

                // todo: for error
                // this._zone.run(()=>observer.error("some error msg"));

            };

            // todo: reader.readAsArrayBuffer(rsp);
            reader.readAsText(blob);

        });
    }

    DecodeAudio$(buffer: ArrayBuffer): Observable<any>
    DecodeAudio$(buffer: ArrayBuffer, uiloader: TUiLoader): Observable<any>
    DecodeAudio$(buffer: ArrayBuffer, uiloader?:TUiLoader): Observable<any> {
        return Observable.create(
            (observer: Observer<any>) => {

                (uiloader)?uiloader():null;

                // webkitAudioContext() is for ios
                //  - also need to declare (top)
                //  - declare var window: any;
                const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

                audioCtx.decodeAudioData(buffer,
                // ios safari error using .then
                //.then(
                    // successCallback
                    (decodedData) => {
                        (uiloader)?uiloader():null;

                        console.log('decoded audio', decodedData);
                        this._zone.run(() => observer.next(decodedData));
                    },
                    // errorCallback
                    (rejected) => {
                        (uiloader)?uiloader():null;

                        console.error('decoded audio rejected', rejected);
                        // pass the error to subscribers
                        //  - error(err:any)
                        //  - passing message string
                        const errorMsg = 'decoding audio rejected';
                        this._zone.run(() => observer.error(errorMsg));
                    }
                );
            }
        );
    }

    // TODO: return typed in observer (string) cuz its text
    HttpGetCdg(url) {
        return Observable.create(
            (observer: Observer<any>) => {

                const options = { responseType: 'text' as 'text' };
                this.http.get(url, options)
                .subscribe(
                    rsp => {
                        console.log('%c ** cdg data rsp.length **  ', 'color:white;background:darkblue', rsp.length);
                        // console.log('%c ** cdg data rsp **  ', 'color:white;background:darkblue', rsp);

                        this._zone.run(() => observer.next(rsp));
                    },
                    error => {
                        console.log('%c ** error **  ', 'color:white;background:red', error);
                        this._zone.run(() => observer.error(error));
                    }
                );
            }
        );
    }

    // TODO: return typed in observer (ArrayBuffer)
    HttpGetAudio(url) {
        return Observable.create(
            (observer: Observer<any>) => {

                const options = { responseType: 'arraybuffer' as 'arraybuffer', withCredentials: true };
                this.http.get(url, options)
                .subscribe(
                    rsp => {
                        console.log('%c ** audio ArrayBuffer **  ', 'color:white;background:darkblue', rsp);
                        this._zone.run(() => observer.next(rsp));
                    },
                    error => {
                        console.log('%c ** HttpGetAudio error **  ', 'color:white;background:red', error);
                        this._zone.run(() => observer.error(error));
                    }
                );
            }
        );
    }

    /**
     * FOR QUICK TESTING ONLY
     */
    TestOnlyCdgPlaySong(song: Song, canvas: ElementRef, border: ElementRef) {
        this.cdg.init(canvas.nativeElement, border.nativeElement);

        console.log('song   -> ', song);
        console.log('canvas -> ', canvas);
        console.log('border -> ', border);
  
        console.log('getting cdg .... ');

        this.HttpGetCdg(song.cdgUrl.cdgUrl)
        .subscribe( 
          // success
          (cdgData) => {
  
            // *******************************************
            this.cdg.setCdgData(cdgData);
  
            console.log('Success: readAsBinaryString')
            console.log('-- cdgData length', cdgData.length);

            // *******************************************
            console.log('getting audio .... ');
            this.HttpGetAudio(song.cdgUrl.mp3Url)
            // success
            .subscribe(
                arrayBuffer => {
                    this.DecodeAudio$(arrayBuffer)
                    .subscribe(audio => {
                        console.log('%c ** audio decoded **  ', 'color:white;background:green');

                        // COMBINE THESE TWO IN CONTROL SERVICE?? OR SOMEWHERE ??
                        this.audioSrvc.setAudioBuffer(audio);
                        this.cdg.setAudioDuration(this.audioSrvc.getDuration())

                        // ********** play **************
                        // this.audioSrvc.play();
                        // this.cdg.playCdg(() => this.audioSrvc.getCurrentTime());

                        // combined, play() and playCdg(), will also set status
                        this.ctrl.play();


                    })
                }
            )
         }, 
         (error) => {
            console.error('-- TestOnlyCdgPlaySong HttpGetCdg', error);
        })
    }

    // // this is for ionic-native
    // FileAPIGetCdg(path, file) {
    //     return Observable.create( (observer: Observer<any>) => {
    //
    //         this.fileApi.readAsBinaryString(path, file)
    //         .then((cdgData) => {
    //
    //             this._zone.run(() => observer.next(cdgData))
    //         })
    //         .catch((error) => {
    //
    //             this._zone.run(() => observer.error(error))
    //         })
    //     })
    // }

    private cdgInitWatcher$: Subject<boolean> = new Subject<boolean>();
    public getCdgInitWatcher() {
        return this.cdgInitWatcher$.asObservable()
    }
    public CdgNeedsToInit(bool) {
        this.cdgInitWatcher$.next(true);
    }


    // Experiment function overload
    // https://stackoverflow.com/questions/12688275/
    // is-there-a-way-to-do-method-overloading-in-typescript/12689054#12689054
    someMethod(stringParameter: string): void;
    someMethod(numberParameter: number, stringParameter: string): void;
    someMethod(stringOrNumberParameter: any, stringParameter?: string): void {
        if (stringOrNumberParameter && typeof stringOrNumberParameter == "number")
            alert("Variant #2: numberParameter = " + stringOrNumberParameter + ", stringParameter = " + stringParameter);
        else
            alert("Variant #1: stringParameter = " + stringOrNumberParameter);
    }

    amethod(url: string): Observable<any>;
    amethod(url: string, uiloader): Observable<any>;
    amethod(url: string, uiloader? ): Observable<any> {
        return Observable.create(
            (observer: Observer<any>) => {
                console.log('%c ** inside amethod **  ', 'color:white;background:red');

                const options = { responseType: 'text' as 'text' };
                (uiloader)?uiloader():null;
                this.http.get(url, options)
                .subscribe(
                    rsp => {
                        (uiloader)?uiloader():null;
                        console.log('%c ** cdg data rsp.length **  ', 'color:white;background:darkblue', rsp.length);
                        this._zone.run(() => observer.next(rsp));
                    },
                    error => {
                        (uiloader)?uiloader():null;
                        console.log('%c ** error **  ', 'color:white;background:red', error);
                        this._zone.run(() => observer.error(error));
                    }
                );
            }
        );

    }

// TObservableCallback
    bmethod(buffer: ArrayBuffer): Observable<any>;
    bmethod(buffer: ArrayBuffer, uiloader): Observable<any>;
    bmethod(buffer: ArrayBuffer, uiloader? ): Observable<any> {
        return Observable.create(
            (observer: Observer<any>) => {
                console.log('%c ** inside bmethod **  ', 'color:white;background:red');

                (uiloader)?uiloader():null;
                // ctx.decodeAudioData(buffer)
                new AudioContext().decodeAudioData(buffer)
                .then(
                    (decodedData) => {
                        (uiloader)?uiloader():null;
                        console.log('decoded audio', decodedData);
                        this._zone.run(() => observer.next(decodedData));
                    },
                    (rejected) => {
                        (uiloader)?uiloader():null;
                        console.error('decoded audio rejected', rejected);
                        // pass the error to subscribers
                        //  - error(err:any)
                        //  - passing message string
                        const errorMsg = 'decoding audio rejected';
                        this._zone.run(() => observer.error(errorMsg));
                    }
                );
            }
        );
    }


} // end class

