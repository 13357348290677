import { Directive, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSpanletters]'
})
export class SpanlettersDirective implements AfterViewInit {

  // do code in ngAfterViewInit
  constructor(private el: ElementRef, private renderer2: Renderer2)  {
  }

  ngAfterViewInit() {

    // can also use el.children
    const natEl = this.el.nativeElement;
    console.log('%c ** natEl.parentElement **  ', 'color:white;background:lightblue', natEl.parentElement);

    // console.log('el', this.el)
    // console.log('natEl.innerHTML', natEl.innerHTML)

    const result = natEl.innerHTML.replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$1</span>")
    console.log('result', result)

    // use renderer2 to create new span
    // const span = this.renderer2.createElement('span')
    // const text = this.renderer2.createText('test')
    // this.renderer2.appendChild(span, text);

    const span = this.renderer2.createElement('span')
    const text = this.renderer2.createText(result)
    this.renderer2.appendChild(span, text);

    span.innerHTML = result;

    this.el.nativeElement.innerText = '';
    this.renderer2.appendChild(this.el.nativeElement, span);

    // console.log('%c ** natEl**  ', 'color:white;background:red', this.el.nativeElement);

  }

}
