import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PopmainmenuComponent } from './components/popmainmenu/popmainmenu.component';

// Angular Material Virtual Scroll and Drag and Drop
// import { ScrollingModule } from '@angular/cdk/scrolling';
// import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [ // what components in this module needs
    // ScrollingModule,
    // DragDropModule,

    CommonModule,
    FormsModule,
    IonicModule,
  ],
  // to use components in this module
  declarations: [PopmainmenuComponent],

  // to use components outside of this module
  exports: [
    PopmainmenuComponent
  ]
})
export class PopoversModule {}
